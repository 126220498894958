import {
  CommitmentPendingPayment,
  PaymentType,
} from "@ifgengineering/client-invest-sdk";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { clientInvestSdk } from "@utils/clientInvestSdk";
import { toast } from "react-toastify";

const FETCH_PENDING_PAYMENTS_API = "FETCH_PENDING_PAYMENTS_API";
const ACKNOWLEDGE_PAYMENT_API = "ACKNOWLEDGE_PAYMENT_API";
const CANCEL_PAYMENT_API = "CANCEL_PAYMENT_API";

export const fetchPendingPaymentsAPI = createAsyncThunk(
  FETCH_PENDING_PAYMENTS_API,
  async (): Promise<CommitmentPendingPayment[]> => {
    const response = await clientInvestSdk.getPendingPayments();
    return response.data;
  }
);

type PostPaymentAPIProps = {
  commitmentId: string;
  paymentType: PaymentType;
};

export const acknowledgePaymentAPI = createAsyncThunk(
  ACKNOWLEDGE_PAYMENT_API,
  async (
    { commitmentId, paymentType }: PostPaymentAPIProps,
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await clientInvestSdk.postPayment({
        commitmentId,
        paymentInput: {
          paymentType,
          status: "USER_ACKNOWLEDGED",
        },
      });
      return response.data;
    } catch (e: any) {
      if (e.response?.status !== 403) {
        toast.error("Something went wrong. Try again or contact support");
      }
      toast.error("Failed to acknowledge payment");
      return rejectWithValue(e);
    }
  }
);

export const cancelPaymentAPI = createAsyncThunk(
  CANCEL_PAYMENT_API,
  async ({ commitmentId, paymentType }: PostPaymentAPIProps) => {
    const response = await clientInvestSdk.postPayment({
      commitmentId,
      paymentInput: {
        paymentType,
        status: "CANCELLED",
      },
    });
    return response.data;
  }
);
