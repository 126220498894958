import { OverridedMixpanel } from "mixpanel-browser";
import { getQueryParam } from "./getQueryParam";

export function campaignParams(mixpanel: OverridedMixpanel): void {
  const campaign_keywords =
    "utm_source utm_medium utm_campaign utm_content utm_term".split(" ");
  const params: Record<string, string> = {};
  const first_params: Record<string, string> = {};
  // Don't break during SSR
  const href = typeof window !== "undefined" ? window.location.href : "";

  for (let index = 0; index < campaign_keywords.length; ++index) {
    const kw = getQueryParam(href, campaign_keywords[index]);

    if (kw !== null && kw.length) {
      params[campaign_keywords[index] + " [last touch]"] = kw;
      first_params[campaign_keywords[index] + " [first touch]"] = kw;
    }
  }

  mixpanel.people.set(params);
  mixpanel.people.set_once(first_params);
  mixpanel.register(params);
}
