export function getQueryParam(url: string, param: string): string | null {
  const sanitizedParam = param.replace(/[[]/, "[").replace(/[]]/, "]");
  const regexS = "[?&]" + sanitizedParam + "=([^&#]*)";
  const regex = new RegExp(regexS);
  const results = regex.exec(url);

  const paramValue = results ? results[1] : "";

  try {
    // if decodeURIComponent receives a string with an wrongly encoded value it will crash
    return decodeURIComponent(paramValue).replace(/\W/gi, " ");
  } catch (e) {
    return null;
  }
}
