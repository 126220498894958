import Card from "@components/Card";
import React, { FC, useEffect } from "react";
import CloseSmall from "@icon-park/react/lib/icons/CloseSmall";
import { CloseButton, Overlay, Window } from "./styled";
import { theme } from "@styles/theme";

export type ModalWindowProps = {
  show: boolean;
  width?: string;
  height?: string;
  padding?: number;
  ignoreHeightAndScroll?: boolean;
  isCloseable?: boolean;
  onClose?: () => void;
};

export const ModalWindow: FC<ModalWindowProps> = ({
  show = false,
  width,
  height,
  padding,
  onClose,
  ignoreHeightAndScroll,
  children,
  isCloseable = true,
}) => {
  useEffect(() => {
    const handleEscKey = (event: KeyboardEvent) => {
      const shouldClose = event.key === "Escape" && isCloseable && onClose;
      if (shouldClose) {
        onClose();
      }
    };

    if (show) {
      document.addEventListener("keydown", handleEscKey);
    }

    return () => {
      document.removeEventListener("keydown", handleEscKey);
    };
  }, [show, isCloseable, onClose]);

  return show ? (
    <Overlay onClick={onClose}>
      <Window
        ignoreHeightAndScroll={ignoreHeightAndScroll}
        width={width}
        height={height}
        onClick={(e) => e.stopPropagation()}
      >
        <Card padding={padding}>
          {isCloseable && (
            <CloseButton onClick={onClose}>
              <CloseSmall size={24} fill={theme.colors.BLUE700} />
            </CloseButton>
          )}
          {children}
        </Card>
      </Window>
    </Overlay>
  ) : null;
};

export default ModalWindow;
