import mixpanelBrowser from "mixpanel-browser";
import { campaignParams } from "./lastTouchUTMs";
import {
  Event,
  AppSwitchProps,
  CryptoProductProps,
  Cur8DealProps,
  EventType,
  ForgotPasswordProps,
  InsightProps,
  InvestItemProps,
  LoginProps,
  ShortlistedItemProps,
  SignupProps,
  TabChangedProps,
  PageViewProps,
  InitArgs,
  cur8PageViewEvents,
  AppNames,
  AnalyticsActions,
  AppStatusProps,
  NotificationProps,
  NotificationAcceptance,
  SetNPSProps,
  SetNPSReasonProps,
  KycYotiCheck,
  AddInvestmentClickedProps,
  DownloadInvestmentStatementClickedProps,
  WithdrawClickedProps,
  MembershipProps,
  NotificationCtaPrompt,
  TimelineEventProps,
  BankAccountsFormSubmittedProps,
  SaleRequestProps,
  SaleConfirmationProps,
} from "./types";

export const init = ({
  APP_NAME,
  GOOGLE_ADS_ID,
  GOOGLE_ADS_SIGNUP_TAG_ID,
  MIXPANEL_TOKEN,
  channel = "web",
  mixpanel = mixpanelBrowser,
}: InitArgs) =>
  async function useAnalytics<T extends Event>(
    event: T
  ): Promise<EventType<T>> {
    if (channel === "mobile") {
      // @ts-ignore mobile library has no params
      mixpanel.init();
    } else {
      mixpanel.init(MIXPANEL_TOKEN);
    }

    // Don't break during SSR
    if (typeof document !== "undefined" && channel !== "mobile") {
      campaignParams(mixpanel);
    }

    let func: any;

    switch (event) {
      case "signupAttempt": {
        func = (args: SignupProps) => {
          mixpanel.track("all:signup_attempted", {
            email: args.email,
            app: APP_NAME,
            channel,
            ...(args.method ? { method: args.method } : {}),
          });
          mixpanel.alias(args.email);
          mixpanel.people.set_once({
            $email: args.email,
            "Sign Up Date": new Date().toISOString(),
            "Account Type": "IFG Wealth",
          });
          mixpanel.identify(args.email);
          // only available in production
          if (window.gtag) {
            window.gtag("event", "conversion", {
              send_to: `${GOOGLE_ADS_ID}/${GOOGLE_ADS_SIGNUP_TAG_ID}`,
            });
          }
        };

        break;
      }

      case "signupSuccess": {
        func = (args: SignupProps) => {
          mixpanel.track("all:signup_success", {
            email: args.email,
            app: APP_NAME,
            distinct_id: args.email,
          });
        };

        break;
      }

      case "login": {
        func = (args: LoginProps) => {
          mixpanel.track("all:login_attempted", {
            email: args.email,
            app: APP_NAME,
            channel,
            ...(args.method ? { method: args.method } : {}),
          });
          mixpanel.people.set({
            LatestLoginDate: new Date().toISOString(),
          });
          mixpanel.people.append("list_logins", new Date().toISOString());
          mixpanel.identify(args.email);
        };

        break;
      }

      case "pageView": {
        func = (args: PageViewProps) => {
          const { eventName, ...rest } = args;
          mixpanel.track(eventName, {
            ...rest,
            app: APP_NAME,
            channel,
          });
        };

        break;
      }

      case "tabChanged": {
        func = (args: TabChangedProps) => {
          mixpanel.track(`${args.eventName}`, {
            app: APP_NAME,
            tab_id: args.tab_id,
          });
        };

        break;
      }

      case "willSubmission": {
        func = () => {
          mixpanel.track("wills:checkout_clicked", {
            app: APP_NAME,
          });
        };

        break;
      }

      case "compareShortlist": {
        func = (args: ShortlistedItemProps) => {
          mixpanel.track("compare:shortlist_clicked", {
            ...args,
            app: APP_NAME,
          });
        };

        break;
      }

      case "compareInvest": {
        func = (args: InvestItemProps) => {
          mixpanel.track("compare:investNow_clicked", {
            ...args,
            app: APP_NAME,
          });
        };

        break;
      }

      case "insightViewClick": {
        func = (args: InsightProps) => {
          mixpanel.track("track:insight_clicked", {
            ...args,
            app: APP_NAME,
          });
        };

        break;
      }

      case "insightCTAClick": {
        func = (args: InsightProps) => {
          mixpanel.track("track:insightCTA_clicked", {
            ...args,
            app: APP_NAME,
          });
        };

        break;
      }
      case "cur8JoinClick": {
        func = () => {
          mixpanel.track("wealth:cur8ProductPageJoin_clicked", {
            app: APP_NAME,
          });
        };

        break;
      }
      case "forogtPasswordClick": {
        func = () => {
          mixpanel.track("all:forgotPassword_clicked", {
            app: APP_NAME,
            channel,
          });
        };

        break;
      }
      case "forogtPasswordSubmitEmailClick": {
        func = (args: ForgotPasswordProps) => {
          mixpanel.track("all:forgotPasswordSubmitEmail_clicked", {
            ...args,
            app: APP_NAME,
            channel,
          });
        };

        break;
      }
      case "forogtPasswordSetPasswordClick": {
        func = () => {
          mixpanel.track("all:forgotPasswordSetPassword_clicked", {
            app: APP_NAME,
          });
        };

        break;
      }

      case "appSwitch": {
        func = async (args: AppSwitchProps) => {
          mixpanel.track("all:app_switched", {
            From: APP_NAME,
            To: args.destination,
            app: APP_NAME,
          });
        };

        break;
      }

      case "cur8DealDetailsPageView": {
        func = async (args: Cur8DealProps) => {
          mixpanel.track("cur8:dealDetails_pageViewed", {
            ...args,
            app: APP_NAME,
            channel,
          });
        };

        break;
      }
      case "cur8DealDetailsCommitInvestmentClick": {
        func = async (args: Cur8DealProps) => {
          mixpanel.track("cur8:dealDetailsCommitInvestment_clicked", {
            ...args,
            app: APP_NAME,
            channel,
          });
        };

        break;
      }
      case "compareCryptoBuyNowClick": {
        func = async (args: CryptoProductProps) => {
          mixpanel.track("compare:cryptoBuyNowClicked", {
            ...args,
            app: APP_NAME,
          });
        };

        break;
      }
      case "cur8BankDetailsPageView": {
        func = async (args: Cur8DealProps) => {
          mixpanel.track("cur8:bankDetails_pageViewed", {
            ...args,
            app: APP_NAME,
            channel,
          });
        };

        break;
      }
      case "bankAccountsFormSubmitted": {
        func = async (args: BankAccountsFormSubmittedProps) => {
          mixpanel.track("cur8:bankAccounts_formSubmitted", {
            ...args,
            app: APP_NAME,
            channel,
          });
        };

        break;
      }
      case "appOpened": {
        func = async (args: AppStatusProps) => {
          mixpanel.track("all:app_opened", {
            ...args,
            app: APP_NAME,
          });
        };

        break;
      }
      case "appClosed": {
        func = async (args: AppStatusProps) => {
          mixpanel.track("all:app_closed", {
            ...args,
            app: APP_NAME,
          });
        };

        break;
      }

      case "notificationCTAClicked": {
        func = async ({ name }: NotificationProps) => {
          mixpanel.track("cur8:notificationCTA_clicked", {
            channel,
            app: APP_NAME,
            notification_name: name,
          });
        };

        break;
      }

      case "notificationsEnabled": {
        func = async (args: NotificationAcceptance) => {
          mixpanel.track("cur8:notifications_enabled", {
            ...args,
            channel,
            app: APP_NAME,
          });
        };

        break;
      }

      case "notificationsDisabled": {
        func = async (args: NotificationAcceptance) => {
          mixpanel.track("cur8:notifications_disabled", {
            ...args,
            channel,
            app: APP_NAME,
          });
        };

        break;
      }

      case "notificationPromptCTA_clicked": {
        func = async (args: NotificationCtaPrompt) => {
          mixpanel.track("cur8:notificationPromptCTA_clicked", {
            ...args,
            channel,
            app: APP_NAME,
          });
        };

        break;
      }

      case "kycYotiCheck": {
        func = async (args: KycYotiCheck) => {
          mixpanel.track("cur8:yotiKyc_check", {
            ...args,
            channel,
            app: APP_NAME,
          });
        };

        break;
      }

      case "addInvestmentClicked": {
        func = async ({ deal_name, ...args }: AddInvestmentClickedProps) => {
          mixpanel.track("cur8:addInvestment_clicked", {
            ...args,
            channel,
            app: APP_NAME,
            deal_name,
          });
        };

        break;
      }

      case "setNPS": {
        func = ({ NPS, email }: SetNPSProps) => {
          mixpanel.identify(email);
          mixpanel.people.set({ NPS });
        };

        break;
      }

      case "setNPSReason": {
        func = ({ NPSReason, email }: SetNPSReasonProps) => {
          mixpanel.identify(email);
          mixpanel.people.set({ NPSReason });
        };

        break;
      }

      case "downloadInvestmentStatementClick": {
        func = async (args: DownloadInvestmentStatementClickedProps) => {
          mixpanel.track("cur8:generateInvestmentStatement_clicked’", {
            ...args,
            channel,
            app: APP_NAME,
          });
        };

        break;
      }

      case "withdrawClick": {
        func = async (args: WithdrawClickedProps) => {
          mixpanel.track("cur8:withdrawButton_clicked", {
            ...args,
            channel,
            app: APP_NAME,
          });
        };

        break;
      }

      case "getMembershipClicked": {
        func = async (args: MembershipProps) => {
          mixpanel.track("cur8:getMembership_clicked", {
            ...args,
            channel,
            app: APP_NAME,
          });
        };

        break;
      }

      case "membershipPurchased": {
        func = async (args: MembershipProps) => {
          mixpanel.track("cur8:membership_purchased", {
            ...args,
            channel,
            app: APP_NAME,
          });
        };

        break;
      }

      case "feesAndReturnsCalculatorInvestNow": {
        func = (args: AppStatusProps) => {
          mixpanel.track("cur8:feesAndReturnsCalculator_investNow", {
            ...args,
            app: APP_NAME,
          });
        };

        break;
      }

      case "zakatCalculatorClicked": {
        func = (args: AppStatusProps) => {
          mixpanel.track("zakat:zakatCalculator_viewed", {
            ...args,
            app: APP_NAME,
          });
        };

        break;
      }

      case "zakatCalculatorCompleted": {
        func = (args: AppStatusProps) => {
          mixpanel.track("zakat:zakatCalculator_completed", {
            ...args,
            app: APP_NAME,
          });
        };

        break;
      }

      case "resumeInstalmentClicked": {
        func = ({ deal_name }: TimelineEventProps) => {
          mixpanel.track("cur8:resumeInstalment_clicked", {
            app: APP_NAME,
            channel,
            deal_name,
          });
        };

        break;
      }

      case "cancelOneOffPaymentClicked": {
        func = ({ deal_name }: TimelineEventProps) => {
          mixpanel.track("cur8:cancelOneOffPayment_clicked", {
            app: APP_NAME,
            channel,
            deal_name,
          });
        };

        break;
      }

      case "cancelInstalmentPlanClicked": {
        func = ({ deal_name }: TimelineEventProps) => {
          mixpanel.track("cur8:cancelInstalmentPlan_clicked", {
            app: APP_NAME,
            channel,
            deal_name,
          });
        };

        break;
      }

      case "activityTimelineCardClicked": {
        func = ({ deal_name }: TimelineEventProps) => {
          mixpanel.track("cur8:activityTimelineCard_clicked", {
            app: APP_NAME,
            channel,
            deal_name,
          });
        };

        break;
      }

      case "paymentAcknowledgementClicked": {
        func = ({ deal_name }: TimelineEventProps) => {
          mixpanel.track("cur8:paymentAcknowledgement_clicked", {
            app: APP_NAME,
            channel,
            deal_name,
          });
        };

        break;
      }

      case "activityTimelineDocumentsTabClicked": {
        func = ({ deal_name }: TimelineEventProps) => {
          mixpanel.track("cur8:activityTimelineDocumentsTab_clicked", {
            app: APP_NAME,
            channel,
            deal_name,
          });
        };

        break;
      }

      case "activityTimelineTabClicked": {
        func = ({ deal_name }: TimelineEventProps) => {
          mixpanel.track("cur8:activityTimelineTab_clicked", {
            app: APP_NAME,
            channel,
            deal_name,
          });
        };

        break;
      }

      case "saleRequestOptionClicked": {
        func = ({ deal_name, payment_ref }: SaleRequestProps) => {
          mixpanel.track("cur8:saleRequestOption_clicked", {
            app: APP_NAME,
            channel,
            deal_name,
            payment_ref,
          });
        };

        break;
      }

      case "saleRequestBankAccountSelected": {
        func = ({ deal_name, payment_ref }: SaleRequestProps) => {
          mixpanel.track("cur8:saleRequestBankAccount_selected", {
            app: APP_NAME,
            channel,
            deal_name,
            payment_ref,
          });
        };

        break;
      }

      case "saleRequestConfirmationClicked": {
        func = ({
          deal_name,
          payment_ref,
          amount,
          liquidationType,
        }: SaleConfirmationProps) => {
          mixpanel.track("cur8:saleRequestConfirmation_clicked", {
            app: APP_NAME,
            channel,
            deal_name,
            payment_ref,
            amount,
            liquidationType,
          });
        };

        break;
      }

      case "mandatoryFCAQuestionsPageViewed": {
        func = () => {
          mixpanel.track("cur8:mandatoryFCAQuestions_pageViewed", {
            app: APP_NAME,
            channel,
          });
        };

        break;
      }
      case "mandatoryFCAQuestionsAnswered": {
        func = () => {
          mixpanel.track("cur8:mandatoryFCAQuestions_answered", {
            app: APP_NAME,
            channel,
          });
        };

        break;
      }
      case "paymentDetailsFCAQuestionsPageViewed": {
        func = () => {
          mixpanel.track("cur8:paymentDetailsFCAQuestions_pageViewed", {
            app: APP_NAME,
            channel,
          });
        };

        break;
      }
      case "paymentDetailsFCAQuestionsAnswered": {
        func = () => {
          mixpanel.track("cur8:paymentDetailsFCAQuestions_answered", {
            app: APP_NAME,
            channel,
          });
        };

        break;
      }

      default:
        func = () => {
          console.log("Analytics operation failed");
        };

        break;
    }

    return func;
  };

export {
  cur8PageViewEvents,
  PageViewProps,
  AppNames,
  AnalyticsActions,
  EventType,
};
