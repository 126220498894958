import React, { FC } from "react";
import { InlineLoader as GenericInlineLoader } from "@ifgengineering/component-library";

interface InlineLoaderProps {
  size?: number;
  thickness?: number;
}
export const InlineLoader: FC<InlineLoaderProps> = ({
  size = 20,
  thickness = 2,
}) => <GenericInlineLoader size={size} thickness={thickness} />;
