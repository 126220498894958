import { AxiosProgressEvent, AxiosPromise, AxiosStatic } from "axios";
import { InvestmentStatementDocInput } from "../types/Documents";

export const downloadInvestmentStatementDocument =
  (serverURL: string, appAxios: AxiosStatic) =>
  async (data: InvestmentStatementDocInput): AxiosPromise =>
    await appAxios.post(`${serverURL}/documents/commitment-overview`, data, {
      withCredentials: true,
    });

export const downloadInvestmentStatementDocumentV2 =
  (serverURL: string, appAxios: AxiosStatic) =>
  async (
    data: InvestmentStatementDocInput,
    onDownloadProgress?: (progressEvent: AxiosProgressEvent) => void
  ): AxiosPromise =>
    await appAxios.post(`${serverURL}/documents/commitment-overview-v2`, data, {
      withCredentials: true,
      responseType: "blob",
      onDownloadProgress,
    });

export const downloadPerformanceOverviewDocument =
  (serverURL: string, appAxios: AxiosStatic) =>
  async (
    data: InvestmentStatementDocInput,
    onDownloadProgress?: (progressEvent: AxiosProgressEvent) => void
  ): AxiosPromise =>
    await appAxios.post(`${serverURL}/documents/performance-overview`, data, {
      withCredentials: true,
      responseType: "blob",
      onDownloadProgress,
    });

export const generateZakatStatement =
  (serverURL: string, appAxios: AxiosStatic) => async (): AxiosPromise =>
    await appAxios({
      url: `${serverURL}/documents/zakat-statement`,
      method: "POST",
      responseType: "arraybuffer",
      withCredentials: true,
    });
