import { getCommitmentHistory } from "./commitmentHistory";
import { getDeal, getDeals, getReturnCalculator } from "./deal";
import {
  getInvestor,
  postNPS,
  getAccreditationQuestionnaire,
  onboardInvestor,
  reaccreditateInvestor,
  submitMandatoryQuestionnaire,
  getMandatoryQuestionnaire,
} from "./investor";
import {
  postCommitment,
  postPayment,
  getCommitments,
  getCommitmentPreview,
  getPaymentHistories,
  getPendingPayments,
} from "./commitment";
import { getPortfolio } from "./portfolio";
import { postQuiz, getQuiz } from "./quiz";
import {
  getEntities,
  postEntityBankAccount,
  postEntity,
  uploadEntityDocument,
  deleteEntityDocument,
} from "./entity";
import {
  uploadKYCDocument,
  deleteKYCDocument,
  postSourceOfFundDescription,
} from "./kyc";
import {
  downloadInvestmentStatementDocument,
  downloadInvestmentStatementDocumentV2,
  downloadPerformanceOverviewDocument,
  generateZakatStatement,
} from "./documents";
import { membershipCheckoutSession } from "./payments";
import {
  deleteUserBankAccount,
  getUserBankAccounts,
  postUserBankAccount,
  putUserBankAccount,
} from "./user-bank-account";
import { createLiquidation, findLiquidationById } from "./liquidation";
import { findWithdrawalById } from "./withdrawal";
import { findInvestmentById } from "./investment";
import { findDividendById } from "./dividend";

export default {
  deleteEntityDocument,
  deleteKYCDocument,
  downloadInvestmentStatementDocument,
  downloadInvestmentStatementDocumentV2,
  downloadPerformanceOverviewDocument,
  getAccreditationQuestionnaire,
  getCommitmentHistory,
  getCommitmentPreview,
  getCommitments,
  getPendingPayments,
  getDeal,
  getDeals,
  getEntities,
  getInvestor,
  getPaymentHistories,
  getPortfolio,
  getQuiz,
  membershipCheckoutSession,
  postCommitment,
  postPayment,
  onboardInvestor,
  postEntity,
  postEntityBankAccount,
  postNPS,
  postQuiz,
  postSourceOfFundDescription,
  reaccreditateInvestor,
  uploadEntityDocument,
  uploadKYCDocument,
  getReturnCalculator,
  generateZakatStatement,
  getUserBankAccounts,
  postUserBankAccount,
  putUserBankAccount,
  deleteUserBankAccount,
  findLiquidationById,
  createLiquidation,
  findWithdrawalById,
  getMandatoryQuestionnaire,
  submitMandatoryQuestionnaire,
  findInvestmentById,
  findDividendById,
};
